<template>
    <!-- begin::Modal Generate Link URL -->
    <b-modal button-size="sm" id="class-customer-link-modal" title="Générer un lien d'inscription">
        <template #default>
            <section v-if="!success">
                <b-form ref="form">
                    <b-overlay :show="loading" rounded="sm">
                        <!-- SELECT CLASS -->
                        <b-form-group v-if="!classeId" label="Classe *" label-for="select-class">
                            <b-form-select name="select-class" v-model="form.classes_customers_id" :options="classesOptions" />
                        </b-form-group>
                        <!-- SELECT CUSTOMER-->
                        <b-form-group label="Etablissement de rattachement des stagiaires" label-for="select-customer">
                            <b-form-select name="select-customer" v-model="customerSelected" :options="customersOptions" />
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox disabled v-model="form.trainee_choice">
                                Laisser le stagiaire choisir son établissement de rattachement
                            </b-form-checkbox>
                        </b-form-group>
                        <!-- EXPIRY -->
                        <b-form-group label="Date d'expiration :" label-for="expiry-date">
                            <b-form-datepicker :min="new Date()" id="expiry-date" v-model="form.expiry" placeholder="Choisissez une date" />
                        </b-form-group>
                        <!-- Limit use -->
                        <b-form-group
                            description="Si vous ne remplissez pas le champ ci-dessus, l'utilisation du lien sera illimitée, dans la mesure où il est actif."
                            label="Nombre d'utilisation maximum :"
                            label-for="limite-use"
                        >
                            <b-form-input type="number" id="limite-use" v-model="form.limit" />
                        </b-form-group>
                        <!-- Double check -->
                        <b-form-group>
                            <b-form-checkbox v-model="form.valid">
                                Demander une validation avant de finaliser l'inscription des stagiaires
                            </b-form-checkbox>
                        </b-form-group>
                        <!-- Online status -->
                        <b-form-group>
                            <b-form-checkbox v-model="form.on"> Activer le lien </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Condition d'inscription" label-for="select-customer">
                            <b-form-select name="select-customer" v-model="form.mail_condition">
                                <b-form-select-option :value="null">Libre</b-form-select-option>
                                <b-form-select-option value="forceMail">Avec adresse email</b-form-select-option>
                                <b-form-select-option value="forceNoMail">Sans adresse email</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-overlay>
                </b-form>
                <p class="text-muted">* : Champs obligatoire</p>
            </section>
            <section v-else-if="success && !loading">
                <div class="text-center">
                    <p class="font-weight-bolder mt-2">
                        Ci-dessous, le lien créé:
                        <a class="text-primary">{{ plateformeHref }}/connexion/{{ token_generated }}</a>
                    </p>
                    <span class="mb-5">
                        Communiquez le pour que les stagiaires s'inscrivent à la classe: <br />
                        {{ classeTitle }}.
                    </span>
                </div>
            </section>
            <div
                v-if="loading"
                style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    background-color: #ffffff9e;
                    backdrop-filter: blur(3px);
                "
                class="d-flex flex-column justify-content-around align-items-center"
            >
                <div style="z-index: 200" class="d-flex flex-column justify-content-around align-items-center">
                    <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
                    <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="createLink()" v-if="!success" :disabled="loading">Générer</b-button>
            <b-button variant="primary" @click="copierElement()" v-if="success">Copier le lien</b-button>
            <b-button variant="primary" @click="reset()" v-if="success">Créer un nouveau lien </b-button>
            <b-button @click="close()" :disabled="loading" v-if="success">Fermer</b-button>
        </template>
    </b-modal>
    <!-- end::Modal Generate Link URL -->
</template>
<script>
import ApiService from '@/core/services/api.service';
import { LIST_ALL_CLIENT } from '@/core/services/store/api/client.service';
import { mapGetters } from 'vuex';

export default {
    name: 'ClassesCustomersGenerateLink',
    data() {
        return {
            link: null,
            success: false,
            loading: false,
            token_generated: null,
            customersOptions: [],
            classesOptions: [{ value: '', text: 'Classe X' }],
            form: {
                classes_customers_id: null,
                customer_id: null,
                select_customer: false,
                limit: null,
                on: true,
                expiry: null,
                valid: true,
                trainee_choice: true,
                mail_condition: null
            },
            customerSelected: 0
        };
    },
    mounted() {
        this.getCustomers();
    },
    props: {
        customerId: {
            type: Number,
            required: true
        },
        classeId: {
            type: Number,
            required: true
        },
        classeTitle: {
            type: String,
            required: true
        }
    },
    computed: {
        checkValidity() {
            return true;
        },
        ...mapGetters({
            customers: 'getClients'
        }),
        plateformeHref() {
            return `${process.env.VUE_APP_PLATEFORME_URL}`;
        }
    },
    watch: {
        customerId: function () {
            this.getCustomers();
        },
        customerSelected: function () {
            this.checkCustomerSelected();
            this.form.trainee_choice === true ? (this.customerSelected = 0) : null;
        }
    },
    methods: {
        getCustomers() {
            const data = {
                params: {
                    customer_id: this.customerId,
                    force: true
                }
            };
            this.$store.dispatch(LIST_ALL_CLIENT, data).then((result) => {
                this.customersOptions = [
                    {
                        value: 0,
                        text: 'Aucun établissement sélectionné'
                    }
                ];
                result &&
                    result.data &&
                    result.data.data.map((customer) => {
                        this.customersOptions.push({
                            value: customer.id,
                            text: `${customer.name} (${customer.code})`
                        });
                    });
            });
        },
        createLink() {
            this.loading = false;
            if (this.checkValidity) {
                let data = { ...this.form };
                this.classeId && !data.classes_customers_id ? (data.classes_customers_id = this.classeId) : null;
                this.customerId && !data.customer_id ? (data.customer_id = this.customerId) : null;
                ApiService.post('academy/entryLink', data)
                    .then((result) => {
                        if (result) {
                            this.success = true;
                        }
                        data.token = result.data.token;
                        this.token_generated = data.token;

                        this.$emit('finish-create');
                    })
                    .finally(() => (this.loading = false));
            }
        },
        checkCustomerSelected() {
            this.customerSelected === 0 ? (this.form.trainee_choice = true) : (this.form.trainee_choice = false);
            this.form.customer_id = this.customerSelected;
        },
        reset() {
            this.loading = false;
            this.success = false;
            this.customerSelected = 0;
            this.form = {
                customer_id: null,
                select_customer: false,
                limit: null,
                on: true,
                expiry: null,
                valid: true,
                trainee_choice: false,
                mail_condition: null
            };
            this.token_generated = null;
        },
        close() {
            this.$bvModal.hide('class-customer-link-modal');
            setTimeout(() => this.reset(), 500);
        },
        copierElement() {
            var copyText = `${this.plateformeHref}/connexion/${this.token_generated}`;
            navigator.clipboard.writeText(copyText);

            this.$bvToast.toast('Lien copié dans le presse-papier', {
                title: 'Succès',
                variant: 'success',
                solid: true
            });
        }
    }
};
</script>
